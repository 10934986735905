import { Grid, Header, Segment } from "semantic-ui-react";
import { ResponsiveContainer } from "../components/DefaultResponsiveContainer";


export default function HomePage(){
    return (
        <ResponsiveContainer>
            <Segment basic vertical style={{justifyContent: 'center', alignItems: 'center'}}>
                <Header as='h3' style={{ fontSize: '2em'}}>
                    Chaguoo Web Page
                </Header>
            </Segment>
            <Segment style={{ padding: '2em 0em'}} vertical>
                <Grid container stackable verticalAlign='middle'>
                    <Grid.Row>
                        <Grid.Column style={{justifyContent: 'left', display: 'flex'}}>
                            <p style={{ fontSize: '1.33em' }}>Welcome to the Chaguoo user web app, you will be able to do multiple functionalities that are not possible on the mobile app.
                            </p>
                        </Grid.Column>
                    </Grid.Row>
                </Grid>
            </Segment>
        </ResponsiveContainer>
    );
}