import { Navigate } from 'react-router-dom';
import { useGeneralContext } from '../storage/context/GeneralContext';

interface ProtectedPageProps{
    page: JSX.Element
}

export default function ProtectedPage({page}: ProtectedPageProps) {
    const {authenticated} = useGeneralContext()
    
    if (!authenticated){
        return <Navigate to={"/login"} />
    }
    return page
}