import { logInWithEmailAndPassword, loginWithAppleFirebase, loginWithGoogleFirebase, logout } from "../api/firebase";
import { useGeneralContext } from "../storage/context/GeneralContext";
import { Button, Container, Divider, Form, Grid, Header, Input, Message, Segment, Popup, Icon } from "semantic-ui-react";
import { ResponsiveContainer } from "../components/DefaultResponsiveContainer";
import { useState } from "react";
import { removeFirebasePrefix } from "../common/format";

export default function LoginPage(){

    const {authenticated} = useGeneralContext();
    const [email, setEmail] = useState<string>('');
    const [passcode, setPassCode] = useState<string>('');
    const [error, setError] = useState<string>('');
    const [isPopupOpen, setIsPopupOpen] = useState(false);


    const onLoginWithMail  = async () => {
        setError((prev) => '');
        if (email.length == 0){
            return setError("Email is required");
        } else if (passcode.length == 0){
            return setError("Passcode is required");
        }
        logInWithEmailAndPassword(email, passcode)
            .then((userCredential) => {})
            .catch((error) => {
                setError((prev) =>removeFirebasePrefix(error.code));
            });
    }

    const handleLoginWithGoogle = () => {
        setError((prev) => '');
        loginWithGoogleFirebase();
    }

    const handleLoginWithApple = () => {
        setError((prev) => '');
        loginWithAppleFirebase();
    }

    const handleLogout = () => {
        logout();
    }

    return (
        <ResponsiveContainer>
            <Header as='h3' style={{ fontSize: '2em'}}>
                Authentication Page
            </Header>
            <p style={{fontSize: '1.2 em'}}>{(authenticated) ? "You are authenticate" : "You need to have an account to be able to access the rest of this web app. To create an account please pass by mobile app." }</p>
            {!authenticated && <Segment basic>
                <Grid centered verticalAlign='middle'>
                    {error && <Grid.Column width={12}>
                        <Message negative>{error}</Message>
                    </Grid.Column>}
                    <Grid.Column width={12}>
                        <Form basic>
                            <Form.Field>
                                <label>Email</label>
                                <Input placeholder='Email' onChange={(event) => setEmail(event.target.value)}/>
                            </Form.Field>
                            <Form.Field>
                                <label>Password</label>
                                <Input type='password' placeholder='Password' onChange={(event) => setPassCode(event.target.value)} />
                            </Form.Field>
                            <Button type='submit' primary onClick={onLoginWithMail}>Submit</Button>
                        </Form>
                    </Grid.Column>
                </Grid>
            </Segment>}
            {!authenticated && <Container><Divider/></Container>}
            <Segment basic>
                <Container className="mt-4">
                    {!authenticated ? 
                    <>
                        <Container style={{marginTop: 10}}>
                            <Button 
                                onClick={handleLoginWithGoogle} 
                                style={{width: 200, height: 50}}
                                color="google plus"
                            >
                                <Icon name="google" /> Login with Google
                            </Button>
                        </Container>
                        <Container style={{marginTop: 10}}>
                            <Button
                                onClick={handleLoginWithApple}
                                style={{width: 200, height: 50}}
                                color="black"
                            >
                                <Icon name="apple" /> Login with Apple
                            </Button>
                        </Container>
                    </> : null}
                    {authenticated ? 
                    <Container style={{marginTop: 10}}>
                        <Button onClick={handleLogout} style={{width: 200, height: 50}} color="red">Logout</Button>
                    </Container> : null}
                </Container>
            </Segment>
        </ResponsiveContainer>
    )
}