import { useState, createContext, ReactNode, useEffect, useContext, ComponentClass } from "react";
import { User } from "firebase/auth";
import { auth } from "../../api/firebase";

interface GeneralContextType{
    currentUser: User | undefined | null;
    authenticated: boolean;
}

const GeneralContext = createContext<GeneralContextType>({
    currentUser: undefined,
    authenticated: false,
});

export const useGeneralContext = () => useContext(GeneralContext);

interface GeneralContextProviderProps{
    children?: ReactNode
}

export default function GeneralContextProvider({children}: GeneralContextProviderProps){
    const [user, setCurrentUser] = useState<User | undefined | null>(undefined);
    const [loading, setLoading] = useState(true);
    const [authenticated, setAuthenticated] = useState<boolean>(true);
    
    const value = {
        currentUser: user,
        authenticated: authenticated,
    };

    useEffect(() => {
        setAuthenticated(!!user);
    }, [user])

    useEffect(() => {
        const unsubscribe = auth.onAuthStateChanged((user) => {
            setCurrentUser(user);
            setAuthenticated(!!user);
            setLoading(false);
        });
        
        return () => unsubscribe();
    }, []);

    return (
        <GeneralContext.Provider value={value}>
            {!loading && children}
        </GeneralContext.Provider>
    );
}