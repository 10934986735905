import './App.css';
import { BrowserRouter as Router, Routes, Route} from "react-router-dom";
import 'semantic-ui-css/semantic.min.css';
import ProtectedPage from './components/ProtectedPage';
import LoginPage from './screens/LoginPage';
import HomePage from './screens/HomePage';
import DeleteAccountPage from './screens/DeleteAccountPage';

function App() {
    return (
        <div className="App">
            <Router>
                <Routes>
                    <Route
                        path='/'
                        element={
                            <ProtectedPage page={<HomePage/>}/>
                        }                  
                    />
                    <Route
                        path='/delete-account'
                        element={
                            <ProtectedPage page={<DeleteAccountPage/>}/>
                        }                    
                    />
                    <Route
                        path='/login'
                        element={<LoginPage/>}                    
                    />
                </Routes>
            </Router>
        </div>
    );
}

export default App;