import { initializeApp } from 'firebase/app';

import { 
    getFirestore, collection, getDocs, 
    setDoc, doc, limit, query, 
    orderBy, startAfter, where, addDoc, deleteDoc
} from 'firebase/firestore';

import { 
    getAuth, signInWithPopup, GoogleAuthProvider, 
    setPersistence, browserSessionPersistence, signOut, onAuthStateChanged, signInWithEmailAndPassword,
    OAuthProvider
} from "firebase/auth";
import { AccountDeletionRequest } from '../models/models';

const firebaseConfig = {
    apiKey: process.env["REACT_APP_FIREBASE_apiKey"],
    authDomain: process.env["REACT_APP_FIREBASE_authDomain"],
    projectId: process.env["REACT_APP_FIREBASE_projectId"],
    storageBucket: process.env["REACT_APP_FIREBASE_storageBucket"],
    messagingSenderId: process.env["REACT_APP_FIREBASE_messagingSenderId"],
    appId: process.env["REACT_APP_FIREBASE_appId"],
    measurementId: process.env["REACT_APP_FIREBASE_measurementId"]
};

const app               = initializeApp(firebaseConfig);
const db                = getFirestore(app);
export const auth       = getAuth(app);
auth.useDeviceLanguage();

const accountDeletionRequestCollectionRef       = collection(db, "accountDeletionRequests");

(async () => {
    await setPersistence(auth, browserSessionPersistence);
})();

const googleProvider    = new GoogleAuthProvider();

// ************************** Authentication Related **************************

export async function loginWithGoogleFirebase() {
    return setPersistence(auth, browserSessionPersistence)
        .then(() => signInWithPopup(auth, googleProvider))
}

export async function logout() {
    return setPersistence(auth, browserSessionPersistence)
        .then(() => signOut(auth))
}

export async function loginWithAppleFirebase() {
    const provider = new OAuthProvider('apple.com');
    provider.addScope('email');
    provider.addScope('name');
    const auth = getAuth();
    signInWithPopup(auth, provider);
}


export async function onAuthStateChangedWrapper() {
    return onAuthStateChanged(auth, (user) => user);
}

export async function logInWithEmailAndPassword(email: string, password: string) {
    return setPersistence(auth, browserSessionPersistence)
        .then(() => signInWithEmailAndPassword(auth, email, password));
}

export async function requestAccountDeletion(data: AccountDeletionRequest) : Promise<string>{
    const querySnapshot = await getDocs(
        query(
            accountDeletionRequestCollectionRef, 
            where("uid", "==", data.uid), 
            limit(1)
        )
    );
    if (!querySnapshot.empty){
        return "A deletion request already exist.";
    }
    await addDoc(
        accountDeletionRequestCollectionRef, 
        data
    );
    return "An account deletion request has been saved";
}